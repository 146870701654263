/*
 * TODO: find a better way to map colors to buttons.
 * Buttons:
 *
 * Button primary
 * Button secondary
 * Button light
 * Button color variants
 * Button outline color variants
 */


// Button primary
// ============================================================================

.btn-primary {
    color: $white !important; // stylelint-disable-line declaration-no-important
}

// Button light
// ============================================================================

.btn-light {
    color: $pink !important; // stylelint-disable-line declaration-no-important
    background-color: $white !important; // stylelint-disable-line declaration-no-important
}

// Button color variants
// ============================================================================

@each $color, $value in $colors {
    .btn-#{$color} {
        @include button-variant(
            $value,
            $value,
            $hover-background: shade-color($value, $btn-hover-bg-shade-amount),
            $hover-border: shade-color($value, $btn-hover-border-shade-amount),
            $active-background: shade-color($value, $btn-active-bg-shade-amount),
            $active-border: shade-color($value, $btn-active-border-shade-amount)
        );
    }
}


// Button outline color variants
// ============================================================================

@each $color, $value in $colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}
