@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono&display=swap');

body {
  font-family: 'Chivo Mono', monospace !important;  
  padding: 2rem;
}
html, body {
  overflow-x: hidden !important;
}

// Progress-bar component 
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 9px;
  background: #ffffff !important;
  transform-origin: 0%;
}

.btn-circle {
  width: 60px;
  height: 60px;
  padding: 10px;
  font-size: 30px;
  // line-height: 1.33;
  border-radius: 50px;
}