// Floating cards animation

.card-floating {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.floating-animation {  
  animation-direction: alternate !important;
  animation-iteration-count: infinite !important;
}
.card-floating-profile {
  // Profile of first card fixed (no animated)
  // @extend .floating-animation;
}
.card-floating-education {
  transform: translateY(100px); /* movement to down */
  animation: floating_bottom_to_top_60 8s linear infinite; 
  @extend .floating-animation;
}
.card-floating-experience {
  transform: translateY(-150px); /* movement to down */
  animation: floating_top_to_bottom_40 10s linear infinite;
  @extend .floating-animation;
}
.card-floating-contact {
  transform: translateY(-200px); /* movement to down */
  animation: floating_top_to_bottom_60 8s linear infinite;
  @extend .floating-animation;
}

/* keyframes */
@keyframes fixed_floating {
  0% { transform: translateY(-40px); }
  100% { transform: translateY(40px); }
}
@keyframes floating_bottom_to_top_60 {
  0% { transform: translateY(50px);  }
  100% { transform: translateY(-50px); }
}
@keyframes floating_top_to_bottom_40 {
  0% { transform: translateY(-40px);  }
  100% { transform: translateY(40px); }
}
@keyframes floating_top_to_bottom_60 {
  0% { transform: translateY(-70px); }
  100% { transform: translateY(70px); }
}

// Dynamic left and right arrows buttons that actives with carousel index selected
.buttons-0{
    color: #464646;
    background: #fff;
    transition: 0.5s;
}
.buttons-1{
    color: #fff;
    background-color: #39ceb0;
    transition: 0.5s;
}
.buttons-2{
    color: #fff;
    background-color: #780193;  
    transition: 0.5s;
}
.buttons-3{
    color: #fff;
    background-color: #00bfd8;  
    transition: 0.5s;
}