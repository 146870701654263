// Images properties for education and technology logos
.education-logo-item{
    width: 100% !important;
    height: 7rem;
    object-fit: contain;
}
  
.technology-logo-item{
    width: 100% !important;
    height: 10rem;
    object-fit: contain;
}