// Tests
.l-r {
    border: 1px solid red;
  }
.l-y{
    border: 1px solid yellow;
}
.l-b {
    border: 1px solid blue;
}
.l-g {
    border: 1px solid green;
}